// Imports
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { Box } from "@mui/system";

import NavigationBar from "../../components/navigation/navigationBar";
import SwiperBanner from "../../components/swipper/swipper.js";
import ExploreCard from "../../components/Card/exploreCard/exploreCard";
import SampleCard from "../../components/Card/sampleCard/sampleCard";
import Footer from "../../components/footer/footer.js";
import { CardMedia, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";

// Imports Swiper
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules

import { Autoplay, Navigation } from "swiper";
import { CategoriesServices } from "app/apis/Categories/CategoriesServices.js";
import {
  ErrorToaster,
  SuccessToaster,
} from "app/components/Toaster/toaster.js";
import Loader from "app/assets/Loader.js";
import Images from "app/assets/images.js";

function Home() {
  const [Categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [productByCategory, setProductByCategory] = useState([]);
  console.log("🚀 productByCategory", productByCategory);
  // Create a ref for the categories section
  const categoriesRef = useRef(null);

  // Handle smooth scrolling based on URL hash
  const location = useLocation();
  const hash = location.hash;
  if (hash === '#categories' && categoriesRef.current) {
    categoriesRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  // Sample Swiper Dummy Data
  let Data = [
    {
      id: 1,
      product_name: "abc",
      Description: "sajdjbn",
      qty: 100,
      price: 1212,
    },
  ];

  // getcategories---------->
  const getCategories = async () => {
    setLoading(true);
    try {
      let result = await CategoriesServices.getCategories();
      if (result.responseCode === 200) {
        const customizableCategory = result.data.filter(
          (item) => item.type === "Customizable"
        );
        setCategories(customizableCategory);
        console.log("customizableCategory=>", customizableCategory);
        getProductsById(customizableCategory[0].id);
      } else {
        ErrorToaster("Oops and Error Occue");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setLoading(false);
    }
  };

  // Get Prducts by iD------>
  const getProductsById = async (ID) => {
    console.log("🚀 ID:", ID);
    setProductsLoading(true);
    try {
      let result = await CategoriesServices.getProductByCategoryID(ID);
      if (result.responseCode === 200) {
        setProductByCategory(result.data);
        console.log("result=>", result);
      } else {
        ErrorToaster("Oops and Error Occur!");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setProductsLoading(false);
    }
  };

  // Tabs Change
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getCategories();
    
  }, [URL]);
  return (
    <>
      {/* Navbar -----------------------------------------------------> */}
      {/* <Box
        sx={{
          md: "fi",
          color: { xs: "black" },
          ":hover": { color: "black", background: "white" },
        }}
        width="100%"
      >
        <NavigationBar />
      </Box> */}

      {/* Banner of  Pictures -------------------->*/}
      
      <SwiperBanner
      style={{
        paddingTop: "56.25%", 
        position: "relative",
      }}
      />

        

        {/* <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h5" align="right" style={{ fontFamily: 'Indie Flower', marginBottom: '20px' }}>
              Revel in your style
            </Typography>
            <Typography variant="h6" align="right" style={{ fontFamily: 'PT Sans', marginBottom: '20px' }}>
              Everyone is different and we have different styles
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: 'center', padding: '20px' }}>
              <p style={{ fontSize: '2.5rem' }}>Embroidered Fashion Fit for the Gods</p>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: 'center', padding: '20px' }}>
              <img src={Images.aboutimage} alt="About" style={{ maxWidth: '100%', height: 'auto' }} />
            </Box>
          </Grid>
        </Grid> */}
      {/* <Box
        sx={{
          backgroundImage: `url(${Images.banner})`,
          width: 1,
          height: { xs: "220px", sm: "300px", md: "400px", lg: "500px" },
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: { xs: "contain", sm: "cover", md: "cover" },
        }}
      /> */}
      {/* Categories Section-------------------------------------------->   */}
      <Box sx={{ padding: { lg: "40px", xs: "20px" }, paddingBottom: "0px" }}>
      <div ref={categoriesRef} id="categories">
        <Box
          sx={{
            mb: {
              xs: "10px",
              md: "30px",
            },
          }}
        >
          {/* <Typography
            component="p"
            variant="h3"
            sx={{
              display: "block",
              textTransform: "uppercase",
              textAlign: "center",
              fontSize: { md: "50px", xs: "15px" },
            }}
          >
            Stay Original
          </Typography> */}
          <Typography
            component="p"
            variant="p"
            sx={{
              display: "block",
              textAlign: "center",
              fontSize: { xs: "12px", md: "20px" },
            }}
          >
            Choose your blanks then add a custom design or choose one from our
            library
          </Typography>
        </Box>
        </div>
        
        <Divider 
        sx={{ 
          borderColor: 'grey.500', 
          borderWidth: 1, 
          marginLeft: 10, 
          marginRight: 10,
          marginBottom: 2,
        }} 
        />

        {/* Categories Section------>   */}
        {loading ? (
          <Box sx={{ height: { xs: "400px", md: "700px" } }}>
            <Loader />
          </Box>
        ) : (
          <>
            {/* Categories Tabs---------------> */}            
            <Box display={"flex"} justifyContent="center">
              <Box
                sx={{
                  width: {
                    xs: "250px",
                    md: "900px",
                  },
                  padding: {
                    xs: "5px",
                    md: "10px",
                  },
                  bgcolor: "background.paper",
                  // border: "1px solid lightgray",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  sx={{
                    ".MuiButtonBase-root": {
                      fontSize: {
                        xs: "10px",
                        md: "15px",
                      },
                      color: "gray,",
                      fontWeight: "600",
                    },
                    ".css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected":
                    {
                      fontWeight: "700",
                      color: "black",
                      fontSize: {
                        xs: "10px",
                        md: "15px",
                      },
                    },
                    fontSize: "15px",
                  }}                  
                >
                  {Categories.map((Item) => (
                    <Tab
                      label={`${Item.name}`}
                      onClick={() => {
                        getProductsById(Item.id);
                      }}
                      sx={{
                        '&:not(:last-of-type)::after': {
                          content: '""',
                          display: 'inline-block',
                          height: '70%',
                          width: '1px',
                          backgroundColor: '#d3d3d3',
                          marginLeft: '10px',
                          position: 'absolute',
                          right: 0,
                          top: '15%',
                        },
                      }}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
             
            {/* Products of category */}
            <Grid container gap="0px" mt={"10px"}>
              {productByCategory.length !== 0 &&
                (productsLoading ? (
                  <Grid item xs="12" md="12" lg="12">
                    <Box sx={{ height: { xs: "400px", md: "700px" } }}>
                      <Loader />
                    </Box>
                  </Grid>
                ) : (
                  productByCategory.map((Item) => {
                    return (
                      <Grid item xs="12" lg="3">
                        <Box sx={{ padding: "10px" }}>
                          <SampleCard
                            name={Item.product_name}
                            Data={Item}
                            description={Item.description}
                            img={Item.product_image}
                            shop={0}
                          />
                        </Box>
                      </Grid>
                    );
                  })
                ))}
            </Grid>
          </>
        )}
      </Box>

      {/* About Section */}
      
        <Grid container spacing={2} sx={{ padding: '5%', bgcolor: '#feeeec' }} >
          <Grid item xs={12} md={6} justifyContent="center" >
            <Box  sx={{ textAlign: 'center', padding: { xs: '10px', md: '40px' }, marginLeft:{ xs:'1px', md: '5%'} , Width: {xs: 'auto', md:'80%'} }} style={{ maxWidth: '80%', height: 'auto',}} >
              <Typography variant="h5" align="left" sx={{ fontFamily: 'Indie Flower', marginBottom: '10px', fontSize: { xs: '18px', md: '35px' },  marginLeft: '20px'  }}>
                Revel in your style
              </Typography>
              <Typography variant="h6" align="left" sx={{ fontFamily: 'PT Sans', marginBottom: '20px', marginLeft: '20px', fontSize: { xs: '15px', md: '18px' }, Width: {xs: '1px', md:'80%'} }}>
                Everyone is different and we have different styles. Don't conform to what people want you to be. Express yourself through personalized embroidered fashion.
              </Typography>
              <Typography variant="h6" align="left" sx={{ fontFamily: 'PT Sans', marginBottom: '25px', marginLeft: '20px', fontSize: { xs: '15px', md: '18px' },  Width: {xs: '1px', md:'80%'} }}>
                Shop our exclusive blank products, then customize them with our original designs. You can also upload your designs and tell us how you want them customized.
              </Typography>

              <Typography variant="h5" align="left" sx={{ fontFamily: 'Indie Flower', marginBottom: '10px', fontSize: { xs: '18px', md: '35px' }, marginTop: '30px', marginLeft: '20px' }}>
                Shop like the gods
              </Typography>
              <Typography variant="h6" align="left" sx={{ fontFamily: 'PT Sans', marginBottom: '20px', marginLeft: '20px', fontSize: { xs: '15px', md: '18px' }, Width: {xs: '1px', md:'80%'} }}>
                Brooklyn Made Kings is located in Brooklyn, New York. Our collections are a reflection of the rich, eclectic, and soulful culture of our community. We would like to share this culture with you through art and fashion.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ padding: {xs: '1px',md : '25px'} }}>
                {/* <Typography variant="h4" sx={{fontFamily: 'PT Sans', fontSize: { xs: '15px', md: '35px', marginBottom: '10px' } }}>Embroidered Fashion Fit for the Gods</Typography> */}
                <img src={Images.aboutimage} alt="About" style={{ maxWidth: '90%', height: 'auto',}} />
            </Box>
          </Grid>
        </Grid>

      {/* <Box sx={{ padding: { lg: "40px", xs: "20px" }, paddingBottom: "0px" }}>
        <Typography
          component="p"
          variant="h3"
          sx={{
            mb: 2,
            textTransform: "uppercase",
            display: "block",
            textAlign: "center",
            fontSize: { md: "50px", xs: "15px" },
          }}
        >
          Revel in your style
        </Typography>

        <Box sx={{ width: { md: "900px", xs: "auto" }, mx: "auto" }}>
          <Grid
            container
            spacing={0}
            justifyContent="space-evenly"
            alignItems={"center"}
          >
            <Grid item sx={{ textAlign: "center" }}>
              <Box component="img" src={Images.artical1} height="400px" />
            </Grid>
            <Grid item sx={{ textAlign: "center" }}>
              <Box component="img" src={Images.artical2} height="400px" />
            </Grid>
            <Grid item sx={{ textAlign: "center" }}>
              <Box component="img" src={Images.artical3} height="400px" />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ width: { md: "1000px", xs: "auto" }, mx: "auto", mt: 1.5 }}>
          <Grid
            container
            spacing={0}
            justifyContent="space-evenly"
            alignItems={"center"}
          >
            <Grid item sx={{ textAlign: "center" }}>
              <Box component="img" src={Images.artical4} height="400px" />
            </Grid>
            <Grid item sx={{ textAlign: "center" }}>
              <Box component="img" src={Images.artical5} height="400px" />
            </Grid>
            <Grid item sx={{ textAlign: "center" }}>
              <Box component="img" src={Images.artical6} height="400px" />
            </Grid>
          </Grid>
        </Box>
      </Box> */}
    </>
  );
}

export default Home;
